header {
  height: 100vh;
  padding-top: 4rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.me__photo {
 width: 34.5rem;
 justify-items: center;
 position: absolute;
 left: -110px
} 

.me {
  background: var(--color-primary);
  width: 20rem;
  height: 28rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 2rem;
  border-radius: 12rem 12rem;
  overflow: hidden;

}

.scroll__down {
position: absolute;
right: -2.3rem;
bottom: 5rem;
transform: rotate(90deg);
font-size: 0.9rem;
}

  /* media queries (phone) */
  
  @media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
  
    .header__socials
    .scroll__down {
        display: none;
    }

    .me, .me__photo {
      visibility:hidden
    }

  }