footer {
    height: fit-content;
    display: flex;
    flex-direction: column;
 justify-content: center;
}

.footer__socials {
 display: flex;
 flex-direction: row;
 justify-content: center;
}

.socials-icon {
    font-size: 40px;
    padding: 1rem;
}

.footer__copyright {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 10rem;
}

p {
    color: rgb(166, 166, 163);
    font-size: 10px;
}