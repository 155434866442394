/* .contact {
    display: flex;
  justify-content: center;
  align-items: center;
} */
.container .contact__container {
  margin-top: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact__form {
  display: flex;
  justify-content: center;
}

form {
  align-items: center;
  display: grid;
  gap: 1.2rem;
  width: 50%;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary);
  resize: none;
  color: var(--color-white);
}

/* media queries (tablet) */

@media screen and (max-width: 1024px) {
  form {
    width: 80%;
  }
  section {
    margin-top: 6rem;
  }
}

/* media queries (phone) */

@media screen and (max-width: 600px) {
  form {
    width: 95%;
  }
}