.portfolio__container{
   height: 600px; 
}

.portfolio__item {
  height: 500px;
  width: 500px;
  background: var(--color-bg-variant);
  padding: 0.5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.portfolio__item:hover {
  border-color: var(--color-primary);
  background: transparent;
}

.portfolio__item-img {
  /* width: 300px; */
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 0.5rem 0.2rem;
}

.portfolio__item h5 {
  margin-bottom: 0.5rem ;
}

.portfolio__item-more {
  margin: 1rem;
}

.portfolio__item-more p {
  font-size: 12px;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

button {
  background-color: transparent;
  font-size: 15px;
}
/* media queries (tablet) */

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.2rem;
        margin-bottom: 3rem;
    }
  } 
  
  /* media queries (phone) */
  
   @media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-bottom: 3rem;
    }
    .portfolio__item {
      height: 400px;
    }
  } 